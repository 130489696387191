// Always use class text-m-bold for links
// Image must be .svg to work properly
.link {
  text-decoration: none;
  color: $color-dark-purple;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  width: fit-content;
  transition: all 0.2s;

  &__icon {
    height: 24px;
    width: 24px;
    background-image: url('../../media/images/icons/rarr-dark-purple.svg');
  }

  &:hover {
    color: $color-purple;

    .link__icon {
      background-image: url('../../media/images/icons/rarr-purple.svg');
    }
  }

  &--default {
    &:hover {
      color: $color-dark-purple;
      text-decoration: underline;
    }
  }
}
